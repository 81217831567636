var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { appHost, appScheme } from 'common/appURLSetup';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { NotificationTypes } from 'common/enums/notification/notification-types.enum';
import { UPDATE_USER_NOTIFICATION_SETTINGS } from 'common/graphql/mutations/user/updateUser/update-user-notification-settings';
import { USER_NOTIFICATION_SETTINGS } from 'common/graphql/queries/user/user-notification-settings';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import { useUpdateUserMutation } from 'common/hooks/useUpdateUserMutation';
import { telegramBotId } from 'common/socialClientIdsSetup';
import * as queryString from 'querystring';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openSnackbar } from 'store/actions';
import history from 'store/history';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
var TelegramBind = function () {
    var t = useTranslation().t;
    var currentUserProfile = useReactiveVar(userProfileDataVar).userProfile;
    var getUserNotificationSettings = useLazyQuery(USER_NOTIFICATION_SETTINGS)[0];
    var userId = currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id;
    var dispatch = useDispatch();
    var location = useHistory().location;
    var onTelegramBindError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: t(code),
            severity: SnackbarSeverity.ERROR
        }));
    };
    var updateUserNotificationSettings = useMutation(UPDATE_USER_NOTIFICATION_SETTINGS, {
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: USER_NOTIFICATION_SETTINGS
            },
        ]
    })[0];
    var enableTelegramNotifications = function () { return __awaiter(void 0, void 0, void 0, function () {
        var notificationSettings, telegramNotificationSettings, _i, telegramNotificationSettings_1, telegramSettings;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getUserNotificationSettings()];
                case 1:
                    notificationSettings = _b.sent();
                    if (!((_a = notificationSettings === null || notificationSettings === void 0 ? void 0 : notificationSettings.data) === null || _a === void 0 ? void 0 : _a.userNotificationSettings)) return [3 /*break*/, 5];
                    telegramNotificationSettings = notificationSettings.data.userNotificationSettings.filter(function (o) { var _a; return ((_a = o.type) === null || _a === void 0 ? void 0 : _a.type) === NotificationTypes.telegram; });
                    _i = 0, telegramNotificationSettings_1 = telegramNotificationSettings;
                    _b.label = 2;
                case 2:
                    if (!(_i < telegramNotificationSettings_1.length)) return [3 /*break*/, 5];
                    telegramSettings = telegramNotificationSettings_1[_i];
                    return [4 /*yield*/, updateUserNotificationSettings({
                            variables: {
                                updateUserNotificationSettingsData: {
                                    enabled: true,
                                    notificationSettingsId: telegramSettings.id
                                }
                            }
                        })];
                case 3:
                    _b.sent();
                    _b.label = 4;
                case 4:
                    _i++;
                    return [3 /*break*/, 2];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var onTelegramBindSuccess = function () {
        dispatch(openSnackbar({
            message: t('Подключение аккаунта выполнено.'),
            severity: SnackbarSeverity.SUCCESS
        }));
        enableTelegramNotifications();
        history.push('/settings/contacts');
    };
    var handleExecuteMutation = useDynamicMutation(useUpdateUserMutation, onTelegramBindSuccess, onTelegramBindError).handleExecuteMutation;
    useEffect(function () {
        var queryObj = queryString.parse(location.hash);
        if (queryObj && queryObj['#tgAuthResult'] && userId) {
            var telegramUserData = JSON.parse(atob(queryObj['#tgAuthResult']));
            if (telegramUserData === null || telegramUserData === void 0 ? void 0 : telegramUserData.id) {
                handleExecuteMutation({
                    id: userId,
                    contact: {
                        telegram: __assign({}, telegramUserData)
                    }
                });
            }
        }
    }, [location.search, history]);
    var handleRedirect = function () {
        var origin = "".concat(appScheme, "://").concat(appHost);
        var redirectTo = "".concat(appScheme, "://").concat(appHost, "/settings/contacts/telegram");
        window.location.href = "https://oauth.telegram.org/auth?bot_id=".concat(telegramBotId, "&origin=").concat(origin, "&request_access=write&return_to=").concat(redirectTo);
    };
    return (_jsx("div", __assign({ className: "tw-link-btn p-4", onClick: handleRedirect }, { children: t('Подключить') }), void 0));
};
export default TelegramBind;
