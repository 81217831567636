import { URL } from 'common/enums/common/url.enum';
import apiService from './apiService';
var authService = {
    getAccountData: function (config) { return apiService.GET("".concat(URL.AUTH_URL, "/getAccountData"), config); },
    loginByEmail: function (payload) { return apiService.POST("".concat(URL.AUTH_URL, "/email"), payload, undefined); },
    loginByAccessToken: function (payload) {
        return apiService.POST("".concat(URL.AUTH_URL, "/accessToken"), null, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer ".concat(payload.accessToken)
            }
        });
    },
    forgotPassword: function (payload) {
        return apiService.POST("".concat(URL.AUTH_URL, "/start-password-change"), payload, undefined);
    },
    resetPasswordVerification: function (config) {
        return apiService.GET("".concat(URL.VERIFICATION_URL, "/email/valid-verification-exists"), config);
    },
    saveNewPassword: function (payload) {
        return apiService.POST("".concat(URL.AUTH_URL, "/complete-password-change"), payload, undefined);
    },
    googleSignIn: function (payload) { return apiService.POST("".concat(URL.AUTH_URL, "/google"), payload, undefined); },
    vkSignIn: function (payload) { return apiService.POST("".concat(URL.AUTH_URL, "/vk"), payload, undefined); },
    yandexSignIn: function (payload) { return apiService.POST("".concat(URL.AUTH_URL, "/yandex"), payload, undefined); },
    telegramSignIn: function (payload) { return apiService.POST("".concat(URL.AUTH_URL, "/telegram"), payload, undefined); },
    stravaSignIn: function (payload) { return apiService.POST("".concat(URL.AUTH_URL, "/strava"), payload, undefined); }
};
export default authService;
