import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { telegramSignIn } from 'store/actions';
var TelegramSignInSeamless = function () {
    var dispatch = useDispatch();
    var search = useLocation().search;
    var useQueryParams = function () {
        return useMemo(function () { return new URLSearchParams(search); }, [search]);
    };
    var query = useQueryParams();
    useEffect(function () {
        var handleLogin = function (telegramData) {
            dispatch(telegramSignIn(telegramData));
        };
        var idStr = query.get('id') || undefined;
        var authDateStr = query.get('auth_date');
        var telegramUserData = {
            id: idStr ? parseInt(idStr) : 0,
            first_name: query.get('first_name') || '',
            last_name: query.get('last_name') || undefined,
            auth_date: authDateStr ? parseInt(authDateStr) : 0,
            username: query.get('username') || '',
            photo_url: query.get('photo_url') || '',
            hash: query.get('hash') || ''
        };
        handleLogin(telegramUserData);
    }, []);
    return null;
};
export default TelegramSignInSeamless;
