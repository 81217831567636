var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import CompatibleWithStrava from 'assets/logos/CompatibleWithStrava';
import userValidationSchema from 'common/validationSchemas/userValidationSchema';
import Animate from 'components/Animate/Animate';
import DividerWithText from 'components/DividerWithText/DividerWithText';
import PasswordField from 'components/Fields/PasswordField';
import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { cleanLoginError, loginByAccessToken, loginByEmail } from 'store/actions';
import { navigateToVar } from 'utils/apollo/reactivities/navigateToVar';
import SocialSignIn from './SocialSignIn/SocialSignIn';
var LoginForm = function () {
    var _a;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var login = useSelector(function (_a) {
        var auth = _a.auth;
        return auth.login;
    });
    var search = useLocation().search;
    var useQueryParams = function () {
        return useMemo(function () { return new URLSearchParams(search); }, [search]);
    };
    var query = useQueryParams();
    var navigateTo = query.get('navigateTo') || undefined;
    var accessToken = query.get('accessToken') || undefined;
    useEffect(function () {
        if (navigateTo) {
            navigateToVar({
                navigateTo: navigateTo
            });
        }
    }, [navigateTo]);
    useEffect(function () {
        if (accessToken) {
            dispatch(loginByAccessToken({ accessToken: accessToken }, navigateTo || undefined));
        }
    }, [accessToken]);
    var navigateToFromVar = useReactiveVar(navigateToVar).navigateTo;
    var handleChangeWrapper = function (e) {
        var _a;
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
        if ((_a = login.error) === null || _a === void 0 ? void 0 : _a.password) {
            dispatch(cleanLoginError());
        }
    };
    var defaultFormState = {
        email: '',
        password: ''
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: userValidationSchema.pick(Object.keys(defaultFormState)),
        validateOnChange: false,
        onSubmit: function (value) {
            dispatch(loginByEmail(value, navigateToFromVar));
            // navigateToVar({
            // 	navigateTo: undefined,
            // });
        },
        validateOnBlur: false
    });
    return (_jsxs(Box, __assign({ sx: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '32rem',
            minWidth: '15rem',
            width: '32rem',
            m: '2rem'
        } }, { children: [_jsx(Box, __assign({ sx: { mb: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center' } }, { children: _jsx(Typography, __assign({ sx: { fontSize: { xs: '2rem', md: '2rem' }, fontWeight: 500, lineHeight: 1 } }, { children: t('Войти') }), void 0) }), void 0), _jsx(Animate, __assign({ delay: 1 }, { children: _jsxs(Box, __assign({ sx: { maxWidth: '100%', mb: '0.5rem' } }, { children: [_jsx(SocialSignIn, {}, void 0), _jsx(DividerWithText, { content: t('или') }, void 0)] }), void 0) }), void 0), _jsxs(Box, __assign({ sx: { width: '100%' } }, { children: [_jsx("form", __assign({ onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } }, { children: [_jsx(TextField, { label: "E-mail", name: "email", value: formik.values['email'], onChange: handleChangeWrapper, error: Boolean(formik.errors['email']), helperText: formik.errors['email'], autoFocus: true }, void 0), _jsx(PasswordField, { id: "password", title: t('Пароль'), value: formik.values['password'], name: "password", onChange: handleChangeWrapper, isError: Boolean(formik.errors['password']), helperText: formik.errors['password'] }, void 0)] }), void 0), ((_a = login.error) === null || _a === void 0 ? void 0 : _a.password) && (_jsx(Box, __assign({ sx: {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '100%',
                                        color: 'red'
                                    } }, { children: _jsx(Typography, { children: t(login.error.password) }, void 0) }), void 0)), _jsx(Box, __assign({ sx: { maxWidth: '100%', mt: '1rem' } }, { children: _jsx(Button, __assign({ sx: { width: '100%' }, type: "submit" }, { children: t('Готов к импульсу') }), void 0) }), void 0), _jsx(Box, __assign({ sx: { display: 'flex', justifyContent: 'flex-end' } }, { children: _jsx(Link, __assign({ to: "/password/forgot" }, { children: t('но забыл пароль') }), void 0) }), void 0)] }), void 0) }), void 0), _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', mt: '2.5rem' } }, { children: [_jsx(Box, __assign({ sx: { width: '100%' } }, { children: _jsx(Divider, {}, void 0) }), void 0), _jsxs(Box, __assign({ sx: { display: 'flex', justifyContent: 'space-between', mt: '1rem' } }, { children: [_jsxs("span", { children: [t('У тебя еще нет Импульса'), "?"] }, void 0), _jsx(Link, __assign({ to: "/register" }, { children: t('Регистрация') }), void 0)] }), void 0), _jsx(CompatibleWithStrava, {}, void 0)] }), void 0)] }), void 0)] }), void 0));
};
export default LoginForm;
