import { URL } from 'common/enums/common/url.enum';
import apiService from './apiService';
var registerService = {
    createUser: function (payload) {
        return apiService.POST("".concat(URL.AUTH_URL, "/email-register"), payload, undefined);
    },
    resendCode: function (payload) { return apiService.POST("".concat(URL.VERIFICATION_URL, "/email/send-key"), payload, undefined); },
    verifyEmailRegistration: function (payload) {
        return apiService.POST("".concat(URL.AUTH_URL, "/email-register-verify"), payload, undefined);
    }
};
export default registerService;
