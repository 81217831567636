var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DialogActions, DialogContent, Typography } from '@mui/material';
import TelegramBind from 'main/pages/SettingsPage/contacts/TelegramBind';
import { useTranslation } from 'react-i18next';
var TelegramAskConnectDialog = function () {
    var t = useTranslation().t;
    return (_jsxs("div", { children: [_jsx(DialogContent, __assign({ className: "dialog_content flex flex-col dark:text-white " }, { children: _jsx(Typography, __assign({ className: "text-16 text-center" }, { children: t('Для включения уведомлений в Telegram необходимо выполнить подключение Telegram к вашему аккаунту в Impulse.') }), void 0) }), void 0), _jsx(DialogActions, __assign({ className: "flex justify-content" }, { children: _jsx(TelegramBind, {}, void 0) }), void 0)] }, void 0));
};
export default TelegramAskConnectDialog;
