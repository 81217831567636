var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import { Box, Button, Hidden, IconButton, ListItemText, MenuItem, Popover, Toolbar } from '@mui/material';
import ImpulseIcon from 'assets/logos/ImpulseIcon';
import clsx from 'clsx';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { USER } from 'common/graphql/queries/user/user';
import DocsHelper from 'components/DocsHelper/DocsHelper';
import NavbarMobileToggleButton from 'main/layouts/shared-components/NavbarMobileToggleButton';
import UserNotificationsIconBadge from 'main/layouts/shared-components/UserNotificationsIconBadge';
import UserRecentNotificationsPopover from 'main/layouts/shared-components/UserRecentNotificationsPopover';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { openDialog } from 'store/actions';
import inMemoryJwt from 'utils/inMemoryJwt';
import UserMenu from '../../shared-components/UserMenu';
var ToolbarLayout = function () {
    var _a = useSelector(function (_a) {
        var base = _a.base;
        return base.settings.current.layout.config;
    }), toolbar = _a.toolbar, navbar = _a.navbar;
    var _b = useState(null), userMenu = _b[0], setUserMenu = _b[1];
    var _c = useState(null), notificationsMenu = _c[0], setNotificationsMenu = _c[1];
    var _d = useState(null), noAuthLanguageMenu = _d[0], setNoAuthLanguageMenu = _d[1];
    var _e = useTranslation(), t = _e.t, i18n = _e.i18n;
    var userNoAuthLang = localStorage.getItem('userNoAuthLang');
    if (userNoAuthLang &&
        ['ru', 'en'].includes(userNoAuthLang) &&
        i18n &&
        i18n.language !== userNoAuthLang &&
        !inMemoryJwt.getToken()) {
        i18n.changeLanguage(userNoAuthLang);
    }
    var userMenuClick = function (event) {
        setUserMenu(event.currentTarget);
    };
    var userMenuClose = function () {
        setUserMenu(null);
    };
    var noAuthLanguageMenuClick = function (event) {
        setNoAuthLanguageMenu(event.currentTarget);
    };
    var noAuthLanguageMenuClose = function () {
        setNoAuthLanguageMenu(null);
    };
    var handleChangeNoAuthLang = function (lang) {
        i18n.changeLanguage(lang);
        localStorage.setItem('userNoAuthLang', lang);
        setNoAuthLanguageMenu(null);
    };
    var _f = useQuery(USER, {
        skip: !inMemoryJwt.getToken(),
        fetchPolicy: 'cache-only',
        variables: {
            showMainSettings: true,
            showRoles: true
        }
    }), data = _f.data, loading = _f.loading;
    var user = data === null || data === void 0 ? void 0 : data.user;
    var dispatch = useDispatch();
    var openChangeLanguageDialog = function () {
        dispatch(openDialog({
            dialogType: DialogTypes.ChangeUserLanguageDialog,
            dialogProps: {
                userId: user === null || user === void 0 ? void 0 : user.id
            }
        }));
    };
    // const { data, loading } = useSubscription(NOTIFICATIONS_SUBSCRIPTION);
    // useEffect(() => {
    // 	if (data) {
    // 		console.log(data);
    // 	}
    // }, [data]);
    var roles = user === null || user === void 0 ? void 0 : user.roles;
    var mainSettings = user === null || user === void 0 ? void 0 : user.mainSettings;
    return (_jsxs(Toolbar, __assign({ className: clsx('tw-toolbar-layout', toolbar.transparent && 'xs:bg-transparent', navbar.display && 'pl-12 pr-0') }, { children: [navbar.display && navbar.position === 'left' && (_jsx(Hidden, __assign({ mdUp: true }, { children: _jsx(NavbarMobileToggleButton, { className: "NavbarMobileToggleButton w-24 h-24 p-0" }, void 0) }), void 0)), _jsx(Box, __assign({ id: "impulse-logo", className: "h-56 flex item-center xs:py-8 xs:px-16 sm:p-0 " }, { children: toolbar.displayLogo && (_jsx(Link, __assign({ className: "flex items-center", to: "/" }, { children: _jsx(ImpulseIcon, {}, void 0) }), void 0)) }), void 0), !user && !loading && (_jsxs("div", __assign({ className: "flex flex-row space-x-10 items-center" }, { children: [_jsx(Button, __assign({ variant: "text", href: "https://docs.ihaveimpulse.ru/".concat(i18n.language === 'en' ? 'en' : ''), target: "_blank" }, { children: t('О проекте') }), void 0), _jsx(IconButton, __assign({ onClick: noAuthLanguageMenuClick }, { children: _jsx(TranslateIcon, { className: "text-white" }, void 0) }), void 0), _jsx(Popover, __assign({ open: Boolean(noAuthLanguageMenu), anchorEl: noAuthLanguageMenu, onClose: noAuthLanguageMenuClose, anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center'
                        }, transformOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        } }, { children: _jsxs(_Fragment, { children: [_jsx(MenuItem, __assign({ selected: i18n.language === 'ru', disabled: i18n.language === 'ru', onClick: function () { return handleChangeNoAuthLang('ru'); } }, { children: _jsx(ListItemText, { primary: "\u0420\u0443\u0441\u0441\u043A\u0438\u0439" }, void 0) }), void 0), _jsx(MenuItem, __assign({ selected: i18n.language === 'en', disabled: i18n.language === 'en', onClick: function () { return handleChangeNoAuthLang('en'); } }, { children: _jsx(ListItemText, { primary: "English" }, void 0) }), void 0)] }, void 0) }), void 0)] }), void 0)), user && (roles === null || roles === void 0 ? void 0 : roles.length) && (_jsxs("div", __assign({ className: "flex items-center max-h-56" }, { children: [_jsx(UserNotificationsIconBadge, { setNotificationsMenu: setNotificationsMenu }, void 0), _jsx(UserMenu, { userName: user.userName, firstName: mainSettings === null || mainSettings === void 0 ? void 0 : mainSettings.firstName, profilePhoto: mainSettings === null || mainSettings === void 0 ? void 0 : mainSettings.profilePhoto }, void 0), _jsx(HelpOutlineOutlinedIcon, { onClick: userMenuClick, color: "primary", className: "h-56 w-32 pr-8 hover:cursor-pointer" }, void 0), _jsx(Popover, __assign({ open: Boolean(userMenu), anchorEl: userMenu, onClose: userMenuClose, anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center'
                        }, transformOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        } }, { children: _jsxs(_Fragment, { children: [_jsx(MenuItem, __assign({ component: Link, to: '/support', onClick: userMenuClose }, { children: _jsx(ListItemText, { primary: t('Служба поддержки') }, void 0) }), void 0), _jsx(MenuItem, __assign({ component: Link, to: '/donate', onClick: userMenuClose }, { children: _jsx(ListItemText, { primary: t('Поддержать проект') }, void 0) }), void 0), _jsx(MenuItem, __assign({ component: Link, to: '/app', onClick: userMenuClose }, { children: _jsx(ListItemText, { primary: t('Приложение') }, void 0) }), void 0), _jsx(DocsHelper, __assign({ id: '9ea92b14-8f2d-483f-988d-1a85583422cd' }, { children: _jsx(MenuItem, { children: _jsx(ListItemText, { primary: t('Справка') }, void 0) }, void 0) }), void 0), _jsx(MenuItem, __assign({ onClick: function () {
                                        openChangeLanguageDialog();
                                        userMenuClose();
                                    } }, { children: _jsxs("div", __assign({ className: "flex flex-row space-x-10 items-center" }, { children: [_jsx(ListItemText, { primary: t('Язык') }, void 0), _jsx(TranslateIcon, {}, void 0)] }), void 0) }), void 0)] }, void 0) }), void 0), _jsx(UserRecentNotificationsPopover, { open: Boolean(notificationsMenu), anchorEl: notificationsMenu, onClose: function () {
                            setNotificationsMenu(null);
                        } }, void 0)] }), void 0))] }), void 0));
};
export default ToolbarLayout;
