export var StandingsParentType;
(function (StandingsParentType) {
    StandingsParentType["None"] = "None";
    StandingsParentType["Sport"] = "Sport";
    StandingsParentType["Team"] = "Team";
    StandingsParentType["SportClub"] = "SportClub";
    StandingsParentType["Tournament"] = "Tournament";
    StandingsParentType["SportEvent"] = "SportEvent";
    StandingsParentType["Community"] = "Community";
})(StandingsParentType || (StandingsParentType = {}));
