var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { FormControlLabel, Switch } from '@mui/material';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { USER } from 'common/graphql/queries/user/user';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openDialog } from 'store/actions';
var UserNotificationSettingsTelegram = function (_a) {
    var _b, _c, _d, _e, _f;
    var userNotificationSettingsGrouped = _a.userNotificationSettingsGrouped, handleExecuteMutation = _a.handleExecuteMutation;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var data = useQuery(USER, {
        variables: {
            showContact: true
        }
    }).data;
    var enabled = (_b = userNotificationSettingsGrouped.statusPerType.telegram) === null || _b === void 0 ? void 0 : _b.enabled;
    var isTelegramConnected = (_e = (_d = (_c = data === null || data === void 0 ? void 0 : data.user) === null || _c === void 0 ? void 0 : _c.contact) === null || _d === void 0 ? void 0 : _d.telegram) === null || _e === void 0 ? void 0 : _e.id;
    var isShowAlert = !isTelegramConnected;
    var onTelegramConnectClick = function (runMutation) {
        var _a;
        if (runMutation) {
            handleExecuteMutation({
                notificationSettingsId: (_a = userNotificationSettingsGrouped.statusPerType.telegram) === null || _a === void 0 ? void 0 : _a.id,
                enabled: !enabled
            });
        }
        if (!isTelegramConnected && !enabled) {
            dispatch(openDialog({
                dialogType: DialogTypes.TelegramAskConnectDialog,
                dialogProps: {}
            }));
        }
    };
    if (!((_f = userNotificationSettingsGrouped === null || userNotificationSettingsGrouped === void 0 ? void 0 : userNotificationSettingsGrouped.statusPerType) === null || _f === void 0 ? void 0 : _f.telegram))
        return null;
    return (_jsxs("div", __assign({ className: "flex flex-col space-y-5 items-start" }, { children: [_jsx(FormControlLabel, { control: _jsx(Switch, { checked: userNotificationSettingsGrouped.statusPerType.telegram.enabled, onChange: function () {
                        onTelegramConnectClick(true);
                    } }, void 0), label: "Telegram" }, void 0), isShowAlert && (_jsxs("p", __assign({ className: "text-12 text-red" }, { children: [t('Для включения уведомлений в телеграм'), ' ', _jsx("span", __assign({ className: "tw-link-btn", onClick: function () {
                            onTelegramConnectClick(false);
                        } }, { children: t('подключите его к Impulse') }), void 0)] }), void 0))] }), void 0));
};
export default UserNotificationSettingsTelegram;
